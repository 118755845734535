import * as React   from "react";
import styled       from "@emotion/styled";
import NotFound from "../../404"

// import "../../subscribe.scss";
import { LinnSubscribe }     from "../../../components/linn-landing/LinnSubscribe";

interface Props {}

const tabletBreakpoint = "900px";

const LinnHomeWorkout:  React.FunctionComponent<Props> = props => {
    return <NotFound/>
    return (
        <LocalWrapper>
            <ImageContainer>
                <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/linnlowes/linn-home-hero.png"></img>
            </ImageContainer>
            <GradientContainer>
                <br/><br/>
                <LinnBox>
                    <br/>
                    <Title>
                        WE’RE BETTER AND
                        {" "}<Break/>
                        STRONGER TOGETHER.
                    </Title>
                    <Paragraph>
                        As we navigate the unknown surrounding COVID-19 together,{" "}
                        I wanted to make sure you had a home workout plan to keep your{" "}
                        mind and body moving during this time of uncertainty.{" "}
                        I’ve just released my all new “Stay Home Keep Fit” program on Fitplan app;{" "}
                        this plan features all time favorite home exercises from all of my{" "}
                        plans that are designed to get you moving and break a sweat. 
                    </Paragraph>
                    <TextLine>
                        My mission is to help make this time a little easier on our fit family and{" "}
                        provide an affordable home workout option to anyone needing it.❤️
                    </TextLine>
                    <TextLine>
                        To redeem your $50 annual membership, select the promo code that applies{" "}
                        to your geographic territory and apply it at check out. 
                    </TextLine>
                    <PromoSheet>
                        <CodeBox>
                            <section>USD:</section>
                            <section>HOME50FP</section>
                        </CodeBox>
                        <CodeBox>
                            <section>Peso<span>(USD)</span>:</section>
                            <section>HOME50PESO</section>
                        </CodeBox>
                        <CodeBox>
                            <section>British Pound:</section>
                            <section>HOME50GBP</section>
                        </CodeBox>
                        <CodeBox>
                            <section>Australia:</section>
                            <section>HOME50AUD</section>
                        </CodeBox>
                        <CodeBox>
                            <section>Canada:</section>
                            <section>HOME50CAD</section>
                        </CodeBox>
                        <CodeBox>
                            <section>Sweden:</section>
                            <section>HOME50KR</section>
                        </CodeBox>
                        <CodeBox>
                            <section>Euros:</section>
                            <section>HOME50EU</section>                           
                        </CodeBox>   
                    </PromoSheet>      
                    <SubscribeContainer>
                        <LinnSubscribe 
                            newCustomersOnly={(
                                <div>
                                    <h1>ERROR</h1>
                                    <h2>This deal is for new members only.</h2>
                                </div>
                            )} />                    
                    </SubscribeContainer>
                </LinnBox>
                <Signature>
                    Together, we got this!
                    <br></br>
                    <img width="240px" src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/linnlowes/linn_sig.png"/>
                </Signature>
            </GradientContainer>
        </LocalWrapper>
    );
};

const LocalWrapper = styled.div`
    font-family: Barlow;

    color:#1d222b;
    background: #FEDEDF;

    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    position: absolute;
    `;

const PromoSheet = styled.div`
    width: 100%;
    margin: 18px auto;

    > div > section {
        min-width: 100px;
        max-width: 100px;
        padding: 0 2px;

        display: inline-block;
    }
    > div > section:first-of-type {
        text-align: right;
    }
    > div > section:last-of-type {
        font-weight: 800;
        text-align: left;
    }

`;
const CodeBox = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 4px;

    span {
        font-size: 80%;
    }
`;


const ImageContainer = styled.div`
    height: 100vw;
    overflow: hidden;

        img {
            height: 100vw;
            position: absolute;
            margin: auto;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

    @media (min-width: ${tabletBreakpoint}){
    height: 43.57vw;

        img {
            width: 100%;
            height: auto;
        }
    }
`;
const GradientContainer = styled.div`
    box-shadow: inset 0 7px 9px -7px rgba(128,130,177,0.4);
    height: 100vh;
`;
const SubscribeContainer = styled.section`
    > div:first-of-type {
        margin-top: -28px;
        background-color:transparent!important;
        background:transparent!important;
    }
    > div > div:first-of-type {
        display:none;
        visibility: hidden;
    }
    > div > div:nth-of-type(2) {
        display:none;
        visibility: hidden;
    }
    > div > div:last-of-type {
        color: #1d222b;
    }
    > div > div > button {
        background: #505050!important;
        &:hover {
            background: #EFB0D7!important;
        }
        > div > div:nth-of-type(2) { color: #EFB0D7!important;}
    }
    > div > div > button > div {
        display: grid;
        grid-template-areas:
            "name name . . action"
            "price price . . action"
            "price price total total total";
        grid-template-columns: auto auto auto 1fr auto;
        grid-template-rows: 1fr auto 1fr;
        grid-gap: 4px;

        @media (min-width: ${tabletBreakpoint}){
            grid-template-areas:
                "name name name . ."
                "price price . . action"
                "price price total total total";
        }
    }
    > div > div > button > div > div:last-of-type {
        visibility: hidden;
        position: relative;
    }
    > div > div > button > div > div:last-of-type:after {
        visibility: visible;
        
        content: "START NOW";
        
        top: 4px;
        left: 0;
        position: absolute;

        @media (min-width: ${tabletBreakpoint}){
            top: 0;
        }
    }
`;


const Break = styled.br`
    @media (min-width: ${tabletBreakpoint}){
        display: none
    }
`;
const LinnBox = styled.div`
    background-color: #fff;
    border-radius: 15px;
    max-width: 600px;
    margin: 10px;

    @media (min-width: ${tabletBreakpoint}){
        margin: 10px auto;
    }
`;

const Title = styled.h1`
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    width: 100%;
`;
const TextLine = styled.p`
    text-align: center;
    padding: 0 20px;
    max-width: 580px;
    margin: 10px auto;

    @media (min-width: ${tabletBreakpoint}){
        padding: 0 60px;
    }
`;
const Paragraph = styled(TextLine)`

    @media (min-width: ${tabletBreakpoint}){
        text-align: justify;
    }
`;

const Signature = styled.div`
    margin: 20px auto;
    text-align: center;
    padding-bottom: 200px;
`;
export default LinnHomeWorkout;